@import '../main.css';

.form {
  width: 80%;
  max-width: 35rem;
  margin-top: 17%
}

.form__row_rightAlign {
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem 0;
}

.footer {
  height: 3rem;
  background-color: var(--blue-10);
}
