@import '../main.css';

.header {
  display: flex;
  align-items: center; /* vertical */
  min-height: 3rem;
  background-color: var(--blue-10);
  padding: 0 1rem;
}

button.header__button {
  margin: .43rem;
}

/* J.C.Info. link (link goes to landing page) */
.header > *:nth-child(1) * {
  font-family: 'Cormorant Garamond', serif;
  text-decoration: none;
  cursor: pointer;
}

.header > *:nth-child(2) {
  flex-grow: 1;
  text-align: center;
}
