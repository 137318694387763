@import "../main.css";

.orgSelector h4 {
  font-weight: 100;
  padding: 3px;
}

.orgSelector div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.orgSelector label {
  font-size: 1rem;
  border-radius: var(--radius-md);
  padding-right: 0.75rem;
  margin-bottom: 0.25rem;
  box-shadow: inset 0 0 10px var(--blue-10);
}
