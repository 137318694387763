@import '../main.css';

main {
  align-items: flex-start; /* vertical */
}

main > div {
  padding: .5rem;
  height: 100%;
}
main > div > :first-child:not(.centered) {
  margin-top: 0;
}

.filterContainer {
  padding: .5rem;
}

.eventsContainer {
  flex-grow: 1;
}

.centered {
  margin: 40%;
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  padding: .5rem;
}

.actionsContainer > button {
  margin-bottom: .5rem;
}