@import "../main.css";

/* TODO apply box shadow that increases when the Event is expanded */
.event {
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: var(--radius-md);
  padding: min(1vw, 12px);
  margin-bottom: 0.25rem;
}

.event * {
  font-weight: 100;
}

.event h3 {
  display: flex;
}

.orgTitle {
  margin-left: 0.5rem;
  font-size: 0.95rem;
}

button.editBtn {
  margin-left: auto;
}
